import React from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import axios from 'axios';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import 'react-image-lightbox/style.css';
import 'react-tenor/dist/styles.css';

let store;
let history;
let App;

window.App = {
  config: {},
  storage: localStorage,
};

async function loadConfig() {
  window.App.config = require('./config.json');
}

async function loadAppComponents() {
  const reduxStore = await import('./redux/store');
  store = reduxStore.default;
  history = reduxStore.history;
  App = (await import('./components/App')).default;
}

const sentryEnabled = async () => {
  const response = await axios.get('/api/secure/get-features');

  if (response.error) {
    return false;
  }

  return response.data.enableSentry;
};

sentryEnabled().then((res) => {
  if (res) {
    Sentry.init({
      dsn:
        'https://d5c86bf8bacd42deafdd6d943a444826@o1079883.ingest.sentry.io/6085057',
      integrations: [new Integrations.BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }
});

const renderApp = () => {
  render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>,
    document.getElementById('app'),
  );
};

(async function run() {
  try {
    await loadConfig();
    await loadAppComponents();
    renderApp();
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
  }
})();

// ReactDOM.render(<App />, document.getElementById('app'));
